<template>
    <div class="know">
        <Swiper></Swiper>
        <div class="know-main">
            <div class="know-left"></div>
            <div class="know-right">
                <div class="know-title">
                    <span>招贤纳士</span>
                    <img src="../assets/img/know-right-title.png" alt="">
                </div>
                <div class="know-main-right">
                    <ul>
                        <li v-for="(item,index) of conList" :key="item.id">
                            <div class="title">
                                <img :src="item.img" alt="">
                                <span>{{item.type}}</span>
                            </div>
                            <div class="content" v-html="item.content"></div>
                            <div class="down" v-if="index==3" @click="downExcel">下载登记表</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  </template>
<script>
    import Swiper from '../components/Swiper.vue'
    import {
        findPeople,
    } from "@/request/api";
    export default {
        components: {
            Swiper,
        },
        data() {
            return {
                downFile: '',
                conList: [],
            }
        },
        created() {
            this.findPeople();
        },
        watch: {
            $route: {
                handler(val, oldval) {
                    this.dimSearch();
                },
                // 深度观察监听
                deep: true
            },
        },

        methods: {
            // 搜索
            findPeople() {
                const _this = this;
                findPeople().then(res => {
                    _this.conList = res.data.findPeopleList;
                    _this.downFile = res.data.path;
                    let path = '';
                    for (let i = 0; i < _this.conList.length; i++) {
                        path = require('../assets/img/know-title-0' + (i + 1) + '.png');
                        _this.conList[i].img = path;
                    }
                    console.log(_this.conList);
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            // 下载表格
            downExcel() {
                // location.href = 'http://www.sdjyxx.cn/' + '最新版---高陵嘉园小学应聘人员登记表2020版.docx';
                location.href =  this.downFile;
            }
        }
    }
</script>
<style scoped lang="scss">
    @import "../../public/css/frame.scss";
    .know-main-right .content {
        text-indent: 0em;
        white-space: pre-wrap;
    }
    
    .down {
        cursor: pointer;
        padding: 0 20px;
        box-sizing: border-box;
        font-size: 16px;
    }
</style>